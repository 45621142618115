<template>
  <div class="submit-form">
    <div v-if="!submitted && user" class="mb-5">
      <div class="mb-3">
        <label for="title" class="form-label">Titel/Anrede</label>
        <input v-model="user.title" type="text" class="form-control" id="title">
      </div>
      <div class="mb-3">
        <label for="forename" class="form-label">Vorname</label>
        <input v-model="user.forename" type="text" class="form-control" id="forename" aria-describedby="forenameHelp">
      </div>
      <div class="mb-3">
        <label for="surname" class="form-label">Nachname</label>
        <input v-model="user.surname" type="text" class="form-control" id="surname" aria-describedby="surnameHelp">
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">E-Mail</label>
        <input v-model="user.email" type="text" class="form-control" id="email" aria-describedby="surnameHelp">
      </div>
      <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input v-model="user.username" type="text" class="form-control" id="username" aria-describedby="usernameHelp">
        <div id="usernameHelp" class="form-text">Standardmäßig alles Kleinbuchstaben.</div>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Passwort</label>
        <input v-model="user.password" type="password" class="form-control" id="password" aria-describedby="passwordHelp">
        <div id="passwordHelp" class="form-text">Bitte wählen Sie ein ausreichend komplexes Passwort.</div>
      </div>

      <table class="table mt-3">
        <thead>
        <tr>
          <th>Rolle</th>
          <th>Mandant</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="mandant in mandants" :key="mandant._id">
          <td>
            <div class="form-check" v-for="(role, index) in roles" :key="`role-${index}`">
              <input
                  type="checkbox"
                  :id="role._id + mandant._id"
                  class="form-check-input"
                  @change='updatePermission(role._id, mandant._id, $event.target.checked)'
                  :checked="user.permissions.find(el => el.mandant._id === mandant._id && el.role._id === role._id)">
              <label class="form-check-label" :for="role._id + mandant._id">{{ role.name }}</label>
            </div>
          </td>
          <td>{{ mandant.mdts }} {{ mandant.firmenbezeichnung.voll }}</td>
        </tr>
        </tbody>
      </table>

      <button @click="updateUser" type="submit" class="btn btn-primary text-white">Speichern</button>

      <button @click="forcePasswordChange" class="btn btn-warning ms-3">Nutzer zur Passwortänderung zwingen</button>
    </div>

    <div v-if="submitted" class="text-center">
      <h4>{{ message }}</h4>
      <button class="btn btn-outline-success mt-3" @click="init">Weitere Änderungen vornehmen</button>
      <router-link class="btn btn-outline-success mt-3 ms-3" to="/users">Alle Benutzer</router-link>
    </div>

    <div v-if="error">
      <p class="text-danger">{{ error }}</p>
    </div>

    <div v-if="message">
      <p class="text-success">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import MandantService from "../services/mandant.service";

export default {
  name: "user",
  data() {
    return {
      user: {},
      roles: {},
      mandants: {},
      changedPermissions: Array(),

      selectedRole: null,
      message: null,
      error: null,
      submitted: false
    };
  },
  methods: {
    init() {
      this.message = null
      this.submitted = false
      this.changedPermissions = []
      this.getMandants()
      this.getRoles()
      this.getUser(this.$route.params.id)
    },

    getUser(id) {
      UserService.get(id)
          .then(response => {
            if (!response.data) {
              this.error = "Der Benutzer wurde nicht gefunden"
              this.submitted = false
            }

            this.user = response.data

            this.user.permissions.forEach(permission => {
              this.updatePermission(permission.role._id, permission.mandant._id, true)
            })
          })
          .catch(e => {
            this.error = e.message
          });
    },

    getMandants() {
      MandantService.getAll()
        .then(response => {
          this.mandants = response.data;
        })
        .catch(reason => {
          this.error = reason.message
        })
    },

    getRoles() {
      UserService.getAllRoles()
        .then(response => {
          this.roles = response.data
        })
        .catch(reason => {
          this.error = reason.response.data.message
        })
    },

    updateUser() {
      let user = this.user
      user.permissions = this.changedPermissions

      UserService.update(user)
          .then(response => {
            this.submitted = true
            this.message = response.data.message
          })
          .catch(e => {
            this.error = e.response.data.message
          });
    },

    updatePermission(roleId, mandantId, checked) {
      if (checked) {
        // enable permission
        this.changedPermissions.push({
          role: roleId,
          mandant: mandantId
        })
      } else {
        this.changedPermissions = this.changedPermissions
            .filter(el => el.role !== roleId || el.mandant !== mandantId)
      }
    },

    forcePasswordChange() {
      let user = this.user
      user.atLogin = { changePassword: true }

      UserService.update(user)
      .then(response => {
        this.submitted = true
        this.message = response.data.message
      })
      .catch(reason => {
        this.error = reason.response.data.message
      })
    }
  },
  mounted() {
    this.init()
  }
};
</script>

<style>
.submit-form {
  max-width: 500px;
  margin: auto;
}
</style>