import http from "../http-common";
import authHeader from "./auth-header";

class UserService {
    getAll() {
        return http.get("/users", { headers: authHeader() });
    }

    get(id) {
        return http.get(`/users/${id}`, { headers: authHeader() });
    }

    update(data) {
        return http.put(`/users/${data._id}`, data, { headers: authHeader() });
    }

    create(data) {
        return http.post("/users", data, { headers: authHeader() });
    }

    search(forename, surname, username) {
        return http.get('/users', {
            params: {
                username: username,
                forename: forename,
                surname: surname
            },
            headers: authHeader()
        });
    }

    getAllRoles() {
        return http.get('/roles', { headers: authHeader() })
    }
}

export default new UserService();